<template>
  <div class="apply-use">
    <div class="header">
      <div class="main-container header-main">
        <div class="welcom">欢迎申请使用</div>
        <img
          class="h-logo"
          src="@/assets/images/layout/other/apply-use/h-logo.png"
          alt=""
        />
        <img
          class="h-banner"
          src="@/assets/images/layout/other/apply-use/h-banner.png"
          alt=""
        />
      </div>
    </div>
    <div class="bodyer main-container">
      <el-form
        ref="form"
        :model="form"
        :rules="rules"
        label-width="130px"
        hide-required-asterisk
        :label-position="labelPosition"
      >
        <el-form-item label="您的姓名" prop="name">
          <el-input v-model="form.name" placeholder="请输入您的姓名"></el-input>
        </el-form-item>
        <el-form-item label="您的手机号码" prop="phone">
          <el-input
            placeholder="请输入您的手机号码"
            v-model="form.phone"
            class="input-with-select"
          >
            <el-select
              v-model="form.area"
              slot="prepend"
              placeholder="请选择"
              popper-class="applyUse"
            >
              <el-option label="+86" value="china">
                <span style="float: left">+86</span>
                <span style="float: right; margin-left: 8px">中国大陆</span>
              </el-option>
              <el-option label="+852" value="hk">
                <span style="float: left">+852</span>
                <span style="float: right; margin-left: 8px">中国香港</span>
              </el-option>
            </el-select>
          </el-input>
          <!-- <el-input v-model="form.name"></el-input> -->
        </el-form-item>
        <el-form-item label="您的电子邮箱" prop="email">
          <el-input
            v-model="form.email"
            placeholder="请输入您的电子邮箱"
          ></el-input>
        </el-form-item>
        <el-form-item label="您的企业名称" prop="company_name">
          <el-input
            v-model="form.company_name"
            placeholder="请输入您的企业名称"
          ></el-input>
        </el-form-item>
        <el-form-item label="您的企业英文缩写" prop="short_cname">
          <el-input
            v-model="form.short_cname"
            placeholder="推荐不要超过4个字符"
          ></el-input>
        </el-form-item>
        <el-form-item label="上传您的企业logo" prop="clogo">
          <div class="flex-h flex">
            <el-upload
              class="avatar-uploader flex-h flex-vc"
              :show-file-list="false"
              :before-upload="beforeAvatarUpload"
              :http-request="uploadAvatar"
              accept="image/png,image/jpeg,image/jpg,image/gif"
              action=""
            >
              <img v-if="imageUrl" :src="imageUrl" class="avatar" />
              <div
                v-else
                class="avatar-uploader-icon flex-h flex-v flex-vc flex-hc"
              >
                <i class="el-icon-plus"></i>
                <span> 上传图片 </span>
              </div>
            </el-upload>
            <div class="tip">png格式，背景透明</div>
          </div>
        </el-form-item>
        <el-form-item label="验证手机" prop="phonecode">
          <div class="flex-h">
            <el-input
              v-model="form.phonecode"
              placeholder="请输入短信验证码"
              maxlength="6"
              class="input-code"
            ></el-input>
            <el-button
              :disabled="getingCode || !form.phone"
              class="getCodeButton"
              plain
              @click="getPhoneCode"
              >{{ getingCode ? `${codeNumer}s` : "获取验证码" }}</el-button
            >
          </div>
        </el-form-item>
        <el-form-item class="submit">
          <el-button type="primary" @click="onSubmit" :disabled="submitDisable"
            >提交申请</el-button
          >
          <div style="text-align: center" v-show="labelPosition === 'top'">
            <span style="font-size: 12px; color: #575859; margin-top: 0px"
              >退出当前页面，请手动点击浏览器返回或者关闭按钮</span
            >
          </div>
        </el-form-item>
      </el-form>
    </div>
    <div class="footer main-container">
      <div class="footer-title">如有疑问请联系专属高级客户经理：</div>
      <div class="concat">
        <div class="concat-item">
          <div class="concat-tip flex-h flex-vc">
            <img
              src="@/assets/images/layout/other/apply-use/icon-call.png"
              alt=""
            />
            <span>电话：</span>
          </div>
          <div class="concat-text">+86 133 1642 1174</div>
        </div>

        <div class="concat-item">
          <div class="concat-tip flex-h flex-vc">
            <img
              src="@/assets/images/layout/other/apply-use/icon-email.png"
              alt=""
            />
            <span>邮箱：</span>
          </div>
          <div class="concat-text">rosemarygong@qkintl.com</div>
        </div>

        <div class="concat-item wx">
          <div class="concat-tip flex-h flex-vc">
            <img
              src="@/assets/images/layout/other/apply-use/icon-wx.png"
              alt=""
            />
            <span>微信：</span>
            <div class="wx-code-box">
              <img
                src="@/assets/images/layout/other/apply-use/wx-code.png"
                alt=""
              />
            </div>
          </div>
          <img
            src="@/assets/images/layout/other/apply-use/wx-code.png"
            alt=""
            class="wx-code"
          />
        </div>
      </div>
    </div>
    <el-dialog
      title=""
      :visible.sync="dialogVisible"
      :width="labelPosition === 'top' ? '343px' : '480px'"
      :modal="false"
      :close-on-click-modal="false"
    >
      <div class="success-dialog flex-v flex-vc">
        <img src="@/assets/images/layout/other/apply-use/success.png" alt="" />
        <span class="success-dialog-tip"> 已提交成功 </span>
        <span>您的申请已经提交成功，我们会及时为您处理。</span>
      </div>
      <div slot="footer" class="flex-h flex-hc">
        <template v-if="labelPosition === 'left'">
          <el-button @click="reSubmit">重新提交</el-button>
          <el-button type="primary" @click="closePage">关闭退出</el-button>
        </template>
        <el-button v-else type="primary" @click="closeDialog">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import api from "@/api";
import { ossPublicUpload } from "@/utils/oss";
import { imgValid } from "@/utils/imgValid.js";
// import service1 from "@/service/service1";
import service2 from "@/service/service2";
// import service from "@/service/service";
// import { Loading } from "element-ui";
import { isHKMobile, isMobile } from "@/utils/core";

let timer;
export default {
  components: {},

  data() {
    return {
      form: {
        area: "china",
        name: "",
        phone: "",
        email: "",
        company_name: "",
        short_cname: "",
        clogo: "",
        phonecode: "",
        device_id: "",
      },
      rules: {
        area: [
          {
            required: true,
            message: "请选择",
            trigger: "change",
          },
        ],
        name: {
          required: true,
          message: "请输入您的姓名",
          trigger: "blur",
        },
        phone: [
          {
            required: true,
            message: "请输入您的手机号",
            trigger: "blur",
          },
          {
            validator: (rule, value, callback) => {
              // 香港手机号码验证

              if (!this.form.area) {
                callback(new Error("请选择区号"));
              } else if (
                (this.form.area === "hk" && !isHKMobile("852-" + value)) ||
                (this.form.area === "china" && !isMobile(value))
              ) {
                callback(new Error("请输入正确的手机号"));
              } else {
                callback();
              }
            },
            trigger: "blur",
          },
        ],
        company_name: [
          {
            required: true,
            message: "请输入您的企业名称",
            trigger: "blur",
          },
        ],
        short_cname: [
          {
            required: true,
            message: "请输入您的企业英文缩写",
            trigger: "blur",
          },
        ],
        clogo: [
          {
            required: true,
            message: "请上传您的企业logo",
            trigger: "blur",
          },
        ],
        phonecode: [
          {
            required: true,
            message: "请输入短信验证码",
            trigger: "blur",
          },
        ],
      },
      area: "china",
      imageUrl: "",
      labelPosition: "left",
      codeNumer: 60,
      dialogVisible: false,
    };
  },
  computed: {
    getingCode() {
      return this.codeNumer < 60;
    },
    submitDisable() {
      const { area, name, phone, company_name, short_cname, clogo, phonecode } =
        this.form;
      return !(
        area &&
        name &&
        phone &&
        company_name &&
        short_cname &&
        clogo &&
        phonecode
      );
    },
  },
  created() {
    this.resize();
    this.form.device_id = this.randomString(12);
    service2.setHeader({
      device_id: this.form.device_id,
    });
    window.addEventListener("resize", this.resize);
  },
  methods: {
    reSubmit() {
      this.dialogVisible = false;
      this.form.phonecode = "";
      clearTimeout(timer);
      this.codeNumer = 60;
    },
    closeDialog() {
      this.dialogVisible = false;
    },
    closePage() {
      if (window.history.length > 1) {
        this.$router.go(-1);
      } else {
        if (
          navigator.userAgent.indexOf("Firefox") != -1 ||
          navigator.userAgent.indexOf("Chrome") != -1
        ) {
          window.location.href = "about:blank";
          window.close();
        } else {
          window.opener = null;
          window.open("", "_self");
          window.close();
        }
      }
    },
    randomString(e) {
      e = e || 32;
      var t = "ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678",
        a = t.length,
        n = "";
      for (let i = 0; i < e; i++) n += t.charAt(Math.floor(Math.random() * a));
      return n;
    },
    resize() {
      // console.log(window);
      let pageWidth;
      pageWidth = window.innerWidth
        ? window.innerWidth
        : document.documentElement && document.documentElement.clientWidth
        ? document.documentElement.clientWidth
        : document.body.offsetWidth;
      if (pageWidth < 568) this.labelPosition = "top";
      else this.labelPosition = "left";
      console.log(pageWidth);
    },
    getPhoneCode() {
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      imgValid()
        .then((result) => {
          // console.log(result);
          const { geetest_challenge } = result;
          api.other
            .phonecodeGet({
              phone: this.form.phone,
              area: this.form.area,
              type: 1,
              scene: "code_login",
              action: "code_login",
              geetest_challenge,
              device_id: this.form.device_id,
            })
            .then((res) => {
              loading.close();
              if (res.data.code == 200) {
                timer = setInterval(() => {
                  this.codeNumer--;
                  if (this.codeNumer === 0) {
                    clearInterval(timer);
                    this.codeNumer = 60;
                  }
                }, 1000);
              } else this.$toast(res.data.msg || "未知错误");
              console.log(res);
            })
            .catch(() => {
              loading.close();
            });
        })
        .catch(() => {
          loading.close();
        });
    },
    beforeAvatarUpload(file) {
      const isJPG =
        file.type === "image/png" ||
        file.type === "image/jpeg" ||
        file.type === "image/jpg" ||
        file.type === "image/gif";
      const isLt2M = file.size / 1024 / 1024 < 20;

      if (!isJPG) {
        this.$message.error("上传图片格式不正确!");
      }
      if (!isLt2M) {
        this.$message.error("上传头像图片大小不能超过 20MB!");
      }
      return isJPG && isLt2M;
    },
    uploadAvatar(file) {
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      let reader = new FileReader();
      reader.readAsDataURL(file.file);
      reader.onload = () => {
        const content = reader.result;
        ossPublicUpload(content)
          .then((res) => {
            this.imageUrl = content || "";
            this.form.clogo = res.name;
            loading.close();
          })
          .catch(() => loading.close());
      };
    },
    onSubmit() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          console.log(this.form);
          api.other.esopTrialApply(this.form).then(() => {
            this.dialogVisible = true;
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.apply-use {
  margin: 0 auto;
  background-color: #f7f8f9;
  font-size: 14px;
  width: 100%;
  min-width: 1440px;
  padding-bottom: 39px;
  .main-container {
    width: 1200px;
    margin: 0 auto;
    // position: relative;
  }
  /deep/ .el-dialog__wrapper {
    .el-dialog {
      padding-left: 0;
      padding-right: 0;
      padding-bottom: 40px;
      .el-dialog__header {
        padding-bottom: 0px;
        border-bottom: none;
      }
    }
    .el-button + .el-button {
      margin-left: 12px;
    }
    .el-button {
      width: 92px;
    }
  }
  .success-dialog {
    padding-top: 14px;
    padding-bottom: 4px;
    color: #575859;
    font-size: 14px;
    img {
      width: 48px;
    }
    &-tip {
      font-size: 18px;
      color: #252626;
      font-weight: 500;
      margin: 16px 0 8px;
    }
  }
  .header {
    font-size: 24px;
    height: 376px;
    position: relative;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 100% 100%;
    background-image: url("../../../assets/images/layout/other/apply-use/h-bg.png");
    color: #fff;
    .header-main {
      position: relative;
      height: 100%;
    }
    .welcom {
      padding-top: 59px;
    }
    .h-logo {
      width: 438px;
      margin-top: 16px;
    }
    .h-banner {
      width: 491px;
      position: absolute;
      right: 84px;
      top: 42px;
      z-index: 100;
    }
  }
  .bodyer {
    padding: 64px 160px 40px;
    background-color: #fff;
    margin-top: -135px;
    position: relative;
    z-index: 10;
    box-shadow: 0px 6px 12px 0px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    box-sizing: border-box;
    /deep/ .input-with-select.el-input.el-input-group.el-input-group--prepend {
      .el-input-group__prepend {
        height: 36px;
        box-sizing: border-box;
        padding-right: 0;
        padding-left: 0px;
        background-color: #fff;
        .el-select {
          margin: 0;
          width: 116px;
          .el-input__inner {
            width: 100%;
            height: 34px;
            color: #575859;
            // width: auto;
            box-sizing: border-box;
          }
        }
      }
    }
    .submit {
      /deep/ .el-form-item__content {
        text-align: center;
        .el-button.el-button--primary {
          width: 300px;
          height: 36px;
          margin-top: 16px;
        }
      }
    }
    .tip {
      align-self: flex-end;
      font-size: 12px;
      line-height: normal;
      color: #ff4940;
      margin-left: 8px;
    }
    .avatar-uploader {
      width: 100px;
      min-height: 100px;
      border: 1px dashed #ebedf0;
      color: #898a8c;
      .avatar {
        width: 100px;
      }
      .avatar-uploader-icon {
        width: 100px;
        min-height: 100px;
        .el-icon-plus {
          font-size: 20px;
          font-weight: bolder;
        }
        span {
          color: #898a8c;
        }
      }

      &:hover,
      &:active {
        border-color: #5990ff;
        background: rgba(50, 125, 255, 0.08);
      }

      border-radius: 5px;
    }
    .input-code {
      flex: 1;
    }
    .getCodeButton {
      width: 94px;
      font-size: 14px;
      padding: 4px 12px;
      margin-left: 16px;
    }
  }
  .footer {
    .footer-title {
      font-size: 18px;
      color: #333333;
      font-weight: 600;
      margin-top: 36px;
    }
    .concat {
      margin-top: 26px;
      display: flex;
      justify-content: space-between;
      .concat-item {
        font-size: 16px;
        color: #333333;
        .concat-tip {
          color: #898a8c;
          font-size: 12px;
          img {
            width: 16px;
            height: 16px;
            margin-right: 2px;
          }
        }
        &.wx {
          .wx-code {
            display: none;
          }
          .wx-code-box {
            width: 80px;
            position: relative;
            img {
              width: 80px;
              height: 80px;
              position: absolute;
              left: 0;
              top: 50%;
              transform: translateY(-50%);
            }
          }
        }
      }
      .concat-text {
        margin-top: 6px;
      }
    }
  }
}
@media screen and (max-width: 568px) {
  .apply-use {
    width: 100%;
    min-width: auto;
    .main-container {
      width: 100%;
      // width: 375px;
      box-sizing: border-box;
      padding: 0 16px;
    }
    /deep/ .el-dialog__wrapper {
      .el-button {
        height: 44px;
      }
    }
    .success-dialog {
      padding-bottom: 2px;
    }
    .header {
      font-size: 18px;
      height: 274px;
      .welcom {
        padding-top: 32px;
        text-align: center;
      }
      .h-logo {
        width: 100%;
        margin-top: 7px;
      }
      .h-banner {
        width: 345px;
        right: 50%;
        top: inherit;
        bottom: -20px;

        transform: translateX(50%);
      }
    }
    .bodyer {
      margin-top: 0;
      padding-top: 28px;
      padding-bottom: 16px;
      box-shadow: none;
      .getCodeButton {
        width: 104px;
      }
      .avatar-uploader .avatar {
        max-width: 200px;
      }
      /deep/
        .input-with-select.el-input.el-input-group.el-input-group--prepend {
        .el-input-group__prepend {
          height: 50px;
          .el-select {
            .el-input__inner {
              height: 48px;
            }
          }
        }
      }
      /deep/ .el-form.el-form--label-top {
        .el-form-item {
          .el-form-item__label {
            line-height: normal;
            padding-bottom: 4px;
          }
          .el-form-item__content {
            .el-input__inner {
              height: 50px;
              line-height: 50px;
              font-size: 16px;
            }
          }
        }
        .submit {
          .el-form-item__content {
            .el-button.el-button--primary {
              width: 100%;
              margin-top: 16px;
              height: 50px;
              font-size: 16px;
              // line-height: 50px;
            }
          }
        }
      }
    }
    .footer {
      .footer-title {
        margin-top: 16px;
      }
      .concat {
        margin-top: 0;
        flex-wrap: wrap;
        .concat-item {
          width: 100%;
          margin-top: 16px;
          &.wx {
            display: flex;
            .concat-tip {
              align-self: flex-start;
            }
            .wx-code {
              width: 80px;
              margin-top: -3px;
              display: block;
            }
            .wx-code-box {
              display: none;
              // img {
              //   top: 0;
              //   transform: translateY(0);
              // }
            }
          }
        }
      }
    }
  }
}
</style>
<style lang="scss">
.el-select-dropdown.el-popper.applyUse {
  .el-scrollbar__bar.is-vertical {
    // display: none;
  }
  .el-select-dropdown__list {
    padding: 0;
    .el-select-dropdown__item {
      // height: 50px;
      // line-height: 50px;
      // font-size: 16px;
      font-weight: 400;
      &.selected {
        background: #f5f7fa;
        color: #575859;
      }
    }
  }
}
@media screen and (max-width: 568px) {
  .el-select-dropdown.el-popper.applyUse {
    .el-select-dropdown__list {
      .el-select-dropdown__item {
        height: 50px;
        line-height: 50px;
        font-size: 16px;
      }
    }
  }
}
</style>
