// 使用oss上传文件
import api from "@/api";
// import { getUserInfoByApp } from "@/utils/getAppBridgeInfo.js";
let OSS = require("ali-oss");
import store from "../store";
// console.log(store);
const userInfo = store.getters.userInfo;
// console.log(userInfo);

function dataURLtoBlob(dataurl) {
  // console.log(dataurl, "试一下url");
  var arr = dataurl.split(","),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new Blob([u8arr], { type: mime });
}

export function ossUpload(files) {
  let file = dataURLtoBlob(files);

  return new Promise((resolve, reject) => {
    api.oss
      .publicStsToken()
      .then((res) => {
        // console.log(res);
        let client = new OSS({
          accessKeyId: res.access_key_id,
          accessKeySecret: res.access_key_secret,
          stsToken: res.security_token,
          bucket: "qkzq-account",
          region: "cn-shenzhen",
          endpoint: "oss-accelerate.aliyuncs.com",
          dir:
            // api.base_img_path +
            // "/" +
            res.dir +
            userInfo.user_id +
            "-" +
            new Date().getTime() +
            "." +
            file.type.substring(file.type.indexOf("image/"), file.type.length),
        });
        client
          .put(
            // api.base_img_path +
            //   "/" +
            res.dir +
              userInfo.user_id +
              "-" +
              new Date().getTime() +
              "." +
              file.type.substring(
                file.type.indexOf("image/") + 6,
                file.type.length
              ),
            file
          )
          .then(function (r1) {
            resolve(r1);
          })
          .catch(() => reject());
      })
      .catch(() => reject());
  });
}

// 切片上传
export async function ossMultipartUploadClient() {
  const res = await api.oss.publicStsToken();
  return {
    client: new OSS({
      accessKeyId: res.access_key_id,
      accessKeySecret: res.access_key_secret,
      stsToken: res.security_token,
      bucket: "qkzq-account",
      region: "cn-shenzhen",
      endpoint: "oss-accelerate.aliyuncs.com",
    }),
    res,
  };
}
// 切片上传
export async function ossMultipartUploadClient1() {
  const res = await api.oss.publicStsToken();
  return {
    client: new OSS({
      accessKeyId: "LTAI5t5gyBPkgrFGyAkF6G4N",
      accessKeySecret: "4PEmadzUSyWS4QJcxsnejsoPxVngQm",
      // stsToken: res.security_token,
      bucket: "qkzq-public",
      region: "cn-shenzhen",
      endpoint: "oss-accelerate.aliyuncs.com",
    }),
    res,
  };
}
//oss下载文件
export async function downLoadFile(url, name) {
  // 区分链接还是id
  if (!url) {
    this.$message.error("下载地址有误");
    return;
  }
  url = decodeURIComponent(url);
  let id;
  if (url && url.split(".com/")[1]) {
    id = url.split(".com/")[1].split("?")[0].split("/").splice(1).join("/");
  } else {
    id = url;
  }
  console.log(id);
  // console.log(id);
  const res = await api.oss.publicStsToken();
  const client = new OSS({
    accessKeyId: res.access_key_id,
    accessKeySecret: res.access_key_secret,
    stsToken: res.security_token,
    bucket: "qkzq-account",
    region: "cn-shenzhen",
    endpoint: "oss-accelerate.aliyuncs.com",
  });
  // 配置响应头实现通过URL访问时自动下载文件，并设置下载后的文件名。
  const filename = name || id; // 自定义下载后的文件名。
  const response = {
    "content-disposition": `attachment; filename=${encodeURIComponent(
      filename
    )}`,
  };
  // 填写Object完整路径。Object完整路径中不能包含Bucket名称。
  const downUrl = client.signatureUrl(`${res.dir}${id}`, { response });
  // window.location.href = downUrl;
  window.open(downUrl);
}
export function ossPublicUpload(files) {
  let file = dataURLtoBlob(files);
  let client = new OSS({
    accessKeyId: "LTAI5t5gyBPkgrFGyAkF6G4N",
    accessKeySecret: "4PEmadzUSyWS4QJcxsnejsoPxVngQm",
    bucket: "qkzq-public",
    region: "cn-shenzhen",
    endpoint: "oss-accelerate.aliyuncs.com",
    dir:
      new Date().getTime() +
      "." +
      file.type.substring(file.type.indexOf("image/"), file.type.length),
  });
  return new Promise((resolve, reject) => {
    client
      .put(
        new Date().getTime() +
          "." +
          file.type.substring(
            file.type.indexOf("image/") + 6,
            file.type.length
          ),
        file
      )
      .then(function (r1) {
        resolve(r1);
      })
      .catch(() => reject());
  });
}
