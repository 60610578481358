import api from "@/api";
// import { Loading } from "element-ui";
export const imgValid = () => {
  // Loading.service({
  //   duration: 0, // 持续展示 toast
  //   forbidClick: true,
  // });
  return new Promise((resolve, reject) => {
    api.other
      .securityGet()
      .then((res) => {
        // console.log(res);
        if (res.data.code == 200) {
          const data = res.data.body;
          window.initGeetest(
            {
              // 以下配置参数来自服务端 SDK
              gt: data.gt,
              challenge: data.challenge,
              offline: !data.success,
              new_captcha: data.new_captcha,
              product: "bind",
            },
            (captchaObj) => {
              captchaObj
                .onReady(() => {
                  captchaObj.verify();
                })
                .onSuccess(() => {
                  const result = captchaObj.getValidate();
                  api.other
                    .securityValid({
                      geetest_challenge: result.geetest_challenge,
                      geetest_validate: result.geetest_validate,
                      geetest_seccode: result.geetest_seccode,
                    })
                    .then((res) => {
                      if (res.data.code == 200) {
                        captchaObj.destroy();
                        resolve(result);
                      }
                    })
                    .catch((err) => {
                      reject(err);
                    });
                })
                .onClose(() => {
                  reject("close");
                })
                .onError(() => {
                  reject("onError");
                  console.log("onError");
                });
            }
          );
        }
      })
      .catch((err) => {
        reject(err);
      });
  });
};
